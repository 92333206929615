<template>
  <section class="products container">
    <div class="products__banner"></div>

    <div class="authorization">
      <div class="auth__title">
        <h1>ИЗБРАННОЕ</h1>
        <div class="auth__counter">
          <h3>КОЛИЧЕСТВО ТОВАРОВ: {{getWishList.length}}</h3>
        </div>
        <!-- <div class="auth__text">
          <p>
            Добавляйте товары в Избранное, чтобы не упустить понравившиеся
            модели.
          </p>
        </div>
        <div class="auth_signin">
          <button>Войти</button>
        </div> -->
      </div>
    </div>

    <div class="products__list">
      <div class="products__wraper">
        <CarouselItem
          v-for="item in getWishList"
          :key="item.product_id"
          :product="item"
          wishlist
        />
      </div>
    </div>

    <div class="products__sale">
      <div class="sale__header">
        <div class="sale__title">
          <h2>НЕ ПРОПУСТИТЕ РАСПРОДАЖУ</h2>
        </div>
        <div class="sale__text">
          <p>Подпишитесь на рассылку нашу, чтобы получать приоритетный доступ к <br> Распродаже и в числе первых узнавать об акциях и новинках.</p>
        </div>
      </div>
      <div class="sale__form">
        <form class="d-flex flex-column align-items-stretch">
          <input
            class="mt-5 mb-3 p-3"
            type="text"
            name="name"
            placeholder="Имя"
          />
          <input
            class="mb-5 p-3"
            type="text"
            name="phone"
            placeholder="E-Mail"
          />
          <button class="btn" type="submit">Подписаться</button>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import CarouselItem from "@/components/home/CarouselItem.vue";
import { mapGetters } from 'vuex';

export default {
  components: {
    CarouselItem,
  },

  computed: {
    ...mapGetters('wishlist', ['getWishList'])
  },

  methods: {
    productDes() {
      this.$router.push({ path: "/products" });
    },
  },
  mounted() {
    console.log(this.getWishList)
  }
};
</script>

<style scoped>
/* .container {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 10px;
} */

.products__banner {
  height: 207px;
  background-color: #fab692;
  margin: 0 auto;
}

.authorization {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: "Exo 2";
  margin-top: 60px;
  margin-bottom: 150px;
}

.auth__title h1 {
  font-size: 34px;
  line-height: 41px;
  line-height: 100%;
}

.auth__title h3 {
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
}

.auth__text {
  width: 100%;
  max-width: 377px;
  margin: 20px 0;
}

.auth__text p {
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
}

.auth_signin button {
  padding: 10px 120px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  background-color: #75b051;
}

.products__list {
  max-width: 1060px;
  margin: 0 auto;
}
.products__wraper {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.products__sale {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 555px;
  margin: 120px auto;
}

.sale__title {
  font-family: 'Exo2' serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
}

.sale__text {
  font-family: 'Exo2' serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

.sale__form input {
  border: 1px solid #7A7A7A;
  border-radius: 5px;
  width: 100%;
  max-width: 270px;
  background-color: #F4F4F4;
}

.sale__form .btn {
  background-color: #75B051;
  color: #ffffff;
  width: 100%;
  max-width: 170px;
  padding: 14px 32px;
  margin: 0 auto;
  border-radius: 5px;
}

@media (max-width: 440px) {
  .products__wraper {
    justify-content: center;
  }
}
</style>
