<template>
  <div class="card" @click.self="productDescription">
    <div v-if="wishlist" class="close" @click="removeFromWishList(product.product_id)">
      <img
        src="@/assets/img/CloseWish.svg"
      />
    </div>
    <img
      class="card-img-top"
      :src="product.thumb"
      alt="Card image cap"
      @click="productDescription"
    />
    <div class="card-body">
      <h5 class="card-title">{{ product.name }}</h5>
      <p class="card-text">
        {{ product.description }}
      </p>
      <div class="card-control">
        <button class="btn" @click="addToCart">Купить</button>
        <!-- <img class="wish" src="@/assets/img/icon-like.svg" alt="" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    wishlist: {
      type: Boolean,
    },
  },
  methods: {
    ...mapActions('wishlist', ['removeFromWishList']),

    productDescription() {
      this.$router.push("/products/" + this.product.name + "_" + this.product.product_id);
      // window.scrollTo(0, 0);
    },
    addToCart() {
      this.$root.$emit("addedToCart", this.product);
    },
  },
};
</script>

<style scoped>
.card {
  position: relative;
  text-align: center;
  width: 100%;
  max-width: 260px;
  margin-bottom: 20px;
  border: none;
  cursor: pointer;
}
.close {
  position: absolute;
  top: 0;
  right: 0;
}
.card:hover {
  box-shadow: 0px 5px 20px -7px rgba(0, 0, 0, 0.25);
}
.card-img-top {
  max-height: 140px;
  width: auto;
  margin: auto;
  margin-bottom: 35px;
}
.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 210px;
}
.card-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;

  color: #000000;
}
.card-text {
  font-family: "Exo 2";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  /* text-transform: uppercase; */

  color: #607874;
}
.card-control {
  width: 100%;
  position: relative;
}

.card .btn {
  max-width: 120px;
  text-align: center;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
}
.btn {
  background: #75b051;
  color: #ffffff;
  border-radius: 5px;
}

.btn:hover {
  background: #ffffff;
  border: 1.57831px solid #75b051;
  box-sizing: border-box;
  color: #7a7a7a;
}

/* For adding product to wish list */

/* .wish {
  display: none;
  position: absolute;
  top: 5px;
  right: 5px;
}

.card-control:hover > img {
  display: inline-flex;
} */

@media (max-width: 768px) {
  .card-body {
    padding: 1rem 0;
  }
}

@media (max-width: 425px) {
  .card-img-top {
    margin-bottom: 0;
  }

  .card-title {
    font-size: 12px;
  }
  .card-text {
    font-size: 10px;
  }
  .card-body {
    max-width: 150px;
    margin: 0 auto;
  }
}
</style>
